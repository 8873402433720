
import { useDevelopers } from '../../composables'

export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { developers, searchByDeveloper } = useDevelopers(emit)

        return {
            developers,
            searchByDeveloper,
        }
    },
    data() {
        return {}
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
