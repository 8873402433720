
import _ from 'lodash'
import he from 'he'
import { get } from 'vuex-pathify'
import { directive } from 'v-aspect-ratio'
import truncate from 'truncate-html'

import { useSearchable } from '@/composables'

import LeadBox from '@/components/content/LeadBox'
import PrivacyBox from '@/components/content/PrivacyBox'
import SwiperGrid2 from '@/components/content/SwiperGrid2'
import ProjectPlanSwipe from '@/components/content/ProjectPlanSwipe'

function isBlank(str) {
    return !str || /^\s*$/.test(str)
}

export default {
    directives: {
        'aspect-ratio': directive,
    },
    components: {
        LeadBox,
        PrivacyBox,
        SwiperGrid2,
        ProjectPlanSwipe,
    },
    setup(props, { emit }) {
        const { currentSearch, currentSearchOption } = useSearchable(emit)

        return {
            currentSearch,
            currentSearchOption,
        }
    },
    async fetch(context) {
        const { error, store, params, route } = context
        try {
            await store.dispatch('deals/getDeal', encodeURI(params.slug))
            // build seo
            const setting = store.state.map
            const article = store.state.deals.deal
            const seo = _.cloneDeep(store.state.seo)

            seo.url = `${setting.url}${route.fullPath}`
            seo.og_url = seo.url
            seo.twitter_url = seo.url

            seo.title = article.name + ' | Propdeal'

            seo.description = isBlank(article.meta_description)
                ? setting.description
                : article.meta_description
            seo.og_description = seo.description
            seo.twitter_description = seo.description
            seo.keywords = isBlank(article.meta_keyword)
                ? setting.meta_keyword
                : article.meta_keyword + ' ' + (setting.meta_keyword || '')
            seo.og_title =
                (isBlank(article.name) ? seo.title : article.name) +
                ' | Propdeal'
            seo.twitter_title = seo.og_title

            seo.og_image = isBlank(article.feature_image)
                ? setting.og_image
                : article.feature_image
            seo.twitter_image = isBlank(article.feature_image)
                ? setting.twitter_image
                : article.feature_image

            await store.commit('SET_SEO', seo)
        } catch (err) {
            if (err.response.status === 404) {
                return error({
                    statusCode: 404,
                    message: err.message,
                })
            } else {
                return error({
                    statusCode: 500,
                    message: err.message,
                })
            }
        }
    },
    data() {
        return {
            facilities: [
                { icon: 'swimming-pool', name: 'Swimming Pool' },
                { icon: 'fitness', name: 'Fitness' },
                { icon: 'co-workingspace', name: 'Co-working Space' },
                { icon: 'entertainment', name: 'Entertainment' },
                { icon: 'auto-carparking', name: 'Automated Parking' },
                { icon: 'garden', name: 'Garden' },
                { icon: 'pet-friendly', name: 'Pet Friendly' },
                { icon: 'security', name: 'Security' },
                { icon: 'lobby', name: 'Lobby' },
                { icon: 'co-kitchen', name: 'Co-Kitchen' },
                { icon: 'spa', name: 'Steam Room / Sauna' },
                { icon: 'mail-room', name: 'Mail Room' },
                { icon: 'kid-facility', name: 'Kid Facility' },
                { icon: 'sky-lounge', name: 'Sky Lounge, Rooftop Facility' },
                { icon: 'clubhouse', name: 'Clubhouse' },
                { icon: 'ev-charger', name: 'EV Charger' },
                { icon: 'jacuzzi', name: 'Jacuzzi' },
                { icon: 'jogging-track', name: 'Jogging Track' },
                { icon: 'laundry-room', name: 'Laundry Room' },
                { icon: 'meeting-room', name: 'Meeting Room' },
                { icon: 'shuttle-bus', name: 'Shuttle Bus' },
            ],
            // houseplan: [
            //     { name: 'Cedar', image: '/images/dummy/home-plan1.png' },
            //     { name: 'Banyan', image: '/images/dummy/home-plan1.png' },
            // ],
            showPrivacy: false,
            showLightBox: false,
            showProjectPlan: false,
            currentPlan: {},
            itemIndex: 0,
            swiperOptions: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 20,
                grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                },
                breakpoints: {
                    1600: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                },
            },
        }
    },
    computed: {
        slug() {
            return this.$route.params.slug
        },
        deal: get('deals/deal'),
        search: get('search'),
        showDetail() {
            if (this.deal.category) {
                return true
            }
            if (this.deal.number_of_building) {
                return true
            }
            if (this.deal.number_of_floor) {
                return true
            }
            if (this.deal.units) {
                return true
            }
            if (this.deal.area) {
                return true
            }
            if (this.deal.living_area) {
                return true
            }
            return false
        },
        galleries() {
            return _.map(this.deal.galleries, (item) => ({
                image: item.url,
            }))
        },
        floor_plan() {
            return _.map(this.deal.floor_plan, (item) => ({
                image: item.url,
            }))
        },
        medias() {
            return _.map(this.galleries, (item) => {
                return {
                    thumb: item.image,
                    src: item.image,
                    // caption: 'รูปภาพแกลเลอรี่',
                }
            })
        },
        mediasPlan() {
            return _.map(this.floor_plan, (item) => {
                return {
                    thumb: item.image,
                    src: item.image,
                    // caption: 'แผนผังห้อง',
                }
            })
        },
        isHouse() {
            return this.deal
                ? this.deal.category.name !== 'คอนโดมีเนียม'
                : false
        },
        houseplan() {
            if (!this.isHouse) return []
            return this.deal.project_plan.map((item) => {
                let url = item.url

                if (typeof url === 'undefined') {
                    if (item.galleries && item.galleries.length > 0) {
                        url = item.galleries[0].url
                    }
                }

                return {
                    detail: item.detail,
                    image: url,
                }
            })
        },
    },
    watch: {
        async slug() {
            await this.$store.dispatch('deals/getDeal', encodeURI(this.slug))
        },
    },
    mounted() {
        // console.log(this.$route.query.house)
    },
    methods: {
        openGallery(index) {
            this.$refs.lightbox.showImage(index)
        },
        openFloorPlan(index) {
            this.$refs.lightboxPlans.showImage(index)
        },
        openProjectPlan(index) {
            this.currentPlan = this.deal.project_plan[index]
            this.showProjectPlan = true
        },
        getIcon(type) {
            if (type === 'การขนส่ง การเดินทาง') {
                return 'fas fa-taxi'
            }
            if (type === 'ช็อปปิ้ง มอลล์') {
                return 'fas fa-shopping-bag'
            }
            if (type === 'สถานที่สำคัญ') {
                return 'fas fa-map-marker-alt'
            }
            if (type === 'สถานศึกษา') {
                return 'fas fa-school'
            }
            if (type === 'โรงพยาบาล') {
                return 'fas fa-hospital'
            }
            return ''
        },
        html(text) {
            let result = ''
            if (text) {
                result = he.decode(text.replace(/\n/g, '<br>'))
                result = truncate(result, 350, {
                    stripTags: false,
                    ellipsis: '',
                })
            }
            return result
        },
    },
}
