
import { get } from 'vuex-pathify'
import _ from 'lodash'

import {
    useLocations,
    useBrands,
    useSearchable,
    useDevelopers,
} from '../composables'

import BannerHero from '../components/content/BannerHero.vue'
import BannerBelow from '../components/content/BannerBelow.vue'
import SwiperGrid from '../components/content/SwiperGrid.vue'

import { HotDeal } from '../components/home'

import { buildSEO } from '../common/utils'

export default {
    components: {
        BannerHero,
        BannerBelow,
        HotDeal,
        SwiperGrid,
    },
    setup(props, { emit }) {
        const { locations, searchByLocation, searchByLocationAndType } =
            useLocations(emit)
        const { brands, searchByBrand } = useBrands(emit)
        const { developers, searchByDeveloper } = useDevelopers(emit)

        const { updateSearchOption } = useSearchable(emit)

        return {
            locations,
            searchByLocation,
            brands,
            searchByBrand,
            developers,
            updateSearchOption,
            searchByDeveloper,
            searchByLocationAndType,
        }
    },
    async fetch(context) {
        const { store } = context
        try {
            await store.dispatch('collection/load', 'trains')
            await store.dispatch('collection/load', 'promotions')
            // fetch location
            await store.dispatch('locations/stats', {
                category: 'บ้านเดี่ยว',
                limit: 8,
            })
            await store.dispatch('locations/stats', {
                category: 'ทาวน์โฮม',
                limit: 8,
            })
            // await store.dispatch('locations/stats', {
            //     category: 'บ้านแฝด',
            //     limit: 8,
            // })
            await store.dispatch('locations/stats', {
                category: 'คอนโดมีเนียม',
                limit: 8,
            })
        } catch (e) {
            // eslint-disable-next-line no-console
            console.err(e)
        }
    },
    data() {
        return {}
    },
    computed: {
        settings: get('settings'),
        stats: get('locations/stats'),
        collections: get('collection/collection'),
        trains() {
            if (!this.collections.trains)
                return {
                    title: 'ดีลติดรถไฟฟ้าแนะนำ',
                    subtitle: 'ดีลล่าสุดติดรถไฟฟ้าทั่วกรุงเทพและปริมณฑล',
                    deals: [],
                }
            const collection = this.collections.trains
            return {
                title: collection.title,
                subtitle: collection.subtitle,
                deals: _.map(collection.deals, (deal) => ({
                    name: deal.name,
                    image: deal.feature_image,
                    developer: deal.developer ? deal.developer.name : '',
                    price: `เริ่มต้น ${deal.price ? deal.price : '-'} ล้านบาท`,
                    slug: deal.slug,
                    trains_nearby: deal.trains_nearby,
                    train_operator_nearby: deal.train_operator_nearby,
                    has_train: deal.has_train,
                })),
            }
        },
        promotions() {
            if (!this.collections.promotions)
                return {
                    title: 'ทำเลยอดนิยมแนะนำ',
                    subtitle:
                        'วันนี้เท่านั้น! 10 ดีลพิเศษจากเจ้าของโครงการชื่อดังทั่วกรุงเทพ ',
                    items: [],
                }
            const collection = this.collections.promotions
            return {
                title: collection.title,
                subtitle: collection.subtitle,
                items: _.map(collection.ads, (ads) => ({
                    name: ads.name,
                    image: ads.feature_image,
                    url: ads.url,
                })),
            }
        },
    },
    async mounted() {
        await buildSEO(this.$store, this.$route, {})
        this.updateSearchOption({})
    },
    methods: {
        locationStats(category) {
            if (this.stats[category]) {
                return this.stats[category]
            } else {
                return []
            }
        },
        openDeal(i, deal) {
            if (typeof deal !== 'undefined') {
                this.$router.push({
                    name: 'deals-slug',
                    params: { slug: deal.slug },
                })
            }
        },
        openLink(i, item) {
            if (typeof item !== 'undefined') {
                window.location.href = item.url
            }
        },
        searchByType(category, index, location) {
            this.searchByLocationAndType(location.title, category)
        },
    },
}
