
import Vue from 'vue'
import _ from 'lodash'
import { get } from 'vuex-pathify'

import { useLocations, useDevelopers, useBrands } from '../../composables'

import TrainList from '../TrainList'

export default {
    components: {
        TrainList,
    },
    props: {
        openPopup: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const { locations, searchByLocation } = useLocations(emit)
        const { developers, searchByDeveloper } = useDevelopers(emit)
        const { brands, searchByBrand } = useBrands(emit)

        return {
            locations,
            searchByLocation,
            developers,
            searchByDeveloper,
            brands,
            searchByBrand,
        }
    },
    data: () => ({
        trains: [],
    }),
    computed: {
        listTrain: get('trains/lines'),
    },
    mounted() {
        this.propulateData()
    },
    methods: {
        explanedTrain(i) {
            const train = this.trains[i]
            train.state = !train.state
            Vue.set(this.trains, i, train)
        },
        propulateData() {
            this.trains = _.cloneDeep(this.listTrain)
        },
    },
}
